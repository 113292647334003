// 交易明细详情管理
import { Request } from '@/http/request'

// 交易明细表
export function tradeDetailsList (parameter: any) {
  return Request.axiosInstance.post('/web/number-trade-details/list', parameter)
}
// 列表导出
export function exportList (parameter: any) {
  return Request.axiosInstance.post('/web/number-trade-details/export', parameter)
}
// 列表导出
export function authNumberCountExport (parameter: any) {
  return Request.axiosInstance.post('/open/cmcc-auth/authNumberCountExport', parameter)
}

export default { tradeDetailsList,exportList,authNumberCountExport }