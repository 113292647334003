<template>
  <!-- 号码池页面 -->

  <div class="number">
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          <span>虚拟号地区</span>
          <span class="cityBox"
            >共有{{ tableData.length }}个城市,{{ totalCount }}个虚拟号</span
          >
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
            <div class="ml-10">
            <el-button @click="exportTableAll" class="button-el" size="medium">
              <icon-font type="icon-daochushuju" class="button-icon-font" />
              导出数据
            </el-button>
          </div>
          <div class="ml-10">
            <el-button
              icon="el-icon-search"
              class="button-el"
              size="medium"
              @click="theQueryDialog = true"
              >查询
            </el-button>
          </div>

          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div
                class="font-refresh-out flex-nowrap-center-center"
                @click="searchTapReset(1)"
              >
                <icon-font
                  v-if="loading == false"
                  type="icon-shuaxin"
                  class="font-refresh"
                />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
          <!-- new -->
        </div>
      </el-col>
    </el-row>
    <!-- 数据列表 -->
    <el-row>
      <el-col :span="24">
        <div>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="700"
            size="mini"
            :row-style="{ height: '50px' }"
            :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="省份" fixed>
              <template #default="scope"
                >{{ scope.row.province == null ? "-" : scope.row.province }}
              </template>
            </el-table-column>
            <el-table-column label="城市">
              <template #default="scope"
                >{{ scope.row.city == null ? "-" : scope.row.city }}
              </template>
            </el-table-column>
            <el-table-column label="可用数量">
              <template #default="scope"
                >{{ scope.row.count == null ? "-" : scope.row.count }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <!-- <div class="pager">
        <div>
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="currentPage" :page-sizes="[10, 20, 30,100,500]"
                         :page-size="searchForm.pageSize"
                         layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div> -->
    <!-- 筛选查询弹窗 -->
    <div>
      <el-dialog
        title="查询"
        v-model="theQueryDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
      >
        <div>
          <el-form
            label-position="left"
            label-width="100px"
            size="small"
            :model="searchForm"
            ref="upform"
          >
            <div class="p-0-30">
              <el-form-item label="地区">
                <el-cascader
                  filterable
                  :options="options"
                  :props="{ value: 'name', label: 'name', checkStrictly: true }"
                  v-model="searchArea"
                  @change="searchAreaChange"
                ></el-cascader>
              </el-form-item>
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="searchTapFun" round>
                  查询
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <!-- 批量解除弹窗 -->
    <div></div>
  </div>
</template>
<script>
import virtualRegionData from "./json.js";
import tradeDetails from '@/api/web/tradeDetails'
import area from "@/api/open/area/area";
import { ElMessageBox, ElMessage } from 'element-plus'
import { numberCount } from "@/api/web/virtualNumber";
import { relationshipUnbinding } from "@/api/web/virtualNumber";
import { message } from "ant-design-vue";
import { IconFont } from "@/utils/iconfont";
import { toRaw } from "vue";
import { getDate } from "@/utils/timeToChoose.js";
import userts from "@/api/web/user";
function compareByCount(a, b) {
  const chineseNumberRegex = /[\u4e00-\u9fa5]/; // Regex to match Chinese characters

  // Function to extract numerical count from the strings with Chinese characters
  function extractCount(str) {
    return chineseNumberRegex.test(str) ? 0 : Number(str);
  }

  const countA = extractCount(a.count);
  const countB = extractCount(b.count);

  if (countA === countB) {
    // If counts are equal, keep the "0(可申请)" count last
    return chineseNumberRegex.test(a.count) ? 1 : -1;
  }
  return countB - countA; // Sort in descending order by count
}

export default {
  name: "Number",
  components: {
    IconFont,
  },
  data() {
    return {
      options: [],
      searchArea: [],

      tableData: [], //数据列表
      tableDataCopy: [], //数据列表

      theQueryDialog: false, //弹窗--查询

      tableHeight: "", //表格高度
      currentPage: 1, //分页
      total: 0, //总数据条数
      totalCount: 0, //总可用数量

      searchForm: {
        page: 1,
        pageSize: 10,
        //搜索项
        uid: "",
        authStatus: "",
        status: "",
        telA: "",
        telX: "",
        bindId: "",
        createTimeBegin: "",
        createTimeEnd: "",
        //   ----
      },

      loading: true, // loding 状态启用
    };
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight;
  },
  mounted() {
    // amount.industryidlist().then((res) => {
    //   this.label = res.data
    // })

    this.getNumberList();

    this.getAreaList();
    
  },
  computed: {},
  methods: {
   exportTableAll() {
      ElMessageBox.confirm('将导出全部数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let province=''
          let city=''
          if (this.searchArea.length==1) {
            
            province=this.searchArea[0]
            if (province.endsWith("省")) {
             province = province.slice(0, -1);
               }
            city=''
          }
           if (this.searchArea.length==2) {
            province=this.searchArea[0]
            city=this.searchArea[1]
               if (province.endsWith("省")) {
             province = province.slice(0, -1);
                  }
            if (city.endsWith("市")) {
            city = city.slice(0, -1);
                 }
          }
          console.log(this.tableData);
          if (this.tableData.length==0) {
            return  ElMessage({
            type: 'info',
            message: '列表暂无导出数据',
          })
          }
          tradeDetails
            .authNumberCountExport({ 
              province: province,
              city:city
              })
            .then((res) => {
              if (res.code !== 200) {
                return ElMessage({
                  type: 'error',
                  message: res.message,
                })
              }
              ElMessage({
                type: 'success',
                message: '列表导出导出任务已建立,稍后请在消息模块下载',
              })
            })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消',
          })
        })
    },
    getAreaList() {
      area.dropDownTreeList().then((res) => {
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },

    async searchTapFun() {
      this.tableData = this.tableDataCopy;

      if (this.searchArea.length == 0) {
        this.getNumberList();
        this.theQueryDialog = false;
        return;
      }
      if (this.searchArea.length == 1) {
        this.tableData = this.tableData.filter((item) =>
          this.searchArea[0].includes(item.province)
        );
        this.theQueryDialog = false;
        var totalCount = 0;
           if (this.tableData.length==0){
this.totalCount=0
           }else{
 this.tableData.forEach(function(item) {
          var currentCount = item.count;
          // 判断count是否是"0(可申请)"
          if (
            typeof currentCount === "number" ||
            (typeof currentCount === "string" &&
              !currentCount.includes("0(可申请)"))
          ) {
            // 如果count是数字或者不包含"0(可申请)"，则累加
            totalCount += parseFloat(currentCount);
          }
        });

        // 输出总和
        this.totalCount = totalCount;
           }
       
       
        return;
      }
      if (this.searchArea.length == 2) {
        this.tableData = this.tableData.filter((item) =>
          this.searchArea[1].includes(item.city)
        );
        this.theQueryDialog = false;
        if (this.tableData.length==0) {
          this.totalCount=0
         }else{
 this.totalCount = this.tableData[0].count;
         }
       
        console.log(this.tableData, this.tableData[0].count);
        return;
      }
    },
    // 列表多选--操作
    handleSelectionChange(val) {
      let newIds = [];
      val.forEach((item) => {
        newIds.push(item.id);
      });
      this.multipleSelection = val;
      this.allIds = newIds;
    },

    // 重置搜索项
    searchTapReset(kind) {
      this.searchArea = [];
      this.loading = true;
      this.getNumberList()
      this.theQueryDialog = false;
    },
    // 获取列表
    getNumberList() {
      this.loading = true;
      numberCount({}).then((res) => {
        this.tableData = res.data;
  // 在遍历数组之前将 totalCount 重置为 0
    this.totalCount = 0;
        this.tableData.forEach((item, index) => {
          this.totalCount += item.count;
          if (item.count == 0) {
            if (
              virtualRegionData.virtualRegionData.filter((e) =>
                item.city.includes(e.name)
              ).length != 0
            ) {
              this.tableData[index].count = "0(可申请)";
            }
          }
        });
        //   console.log("总和: " +this.totalCount);
        this.tableData = this.tableData.filter((item) => item.count != 0);

        this.tableDataCopy = this.tableData;
        this.tableData.sort(compareByCount);
        this.tableData = this.tableData.filter(
          (item) => item.count !== "0(可申请)"
        );
        this.tableData = this.tableData.filter((item) => item.count != 0);

        this.loading = false;
      });
    },
    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.getNumberList();
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.currentPage = val;
      this.getNumberList();
    },
  },
};
</script>
<style lang="scss" scoped>
.number {
  padding-right: 10px;
}

.showIdsList {
  min-height: 30px;
  max-height: 260px;
  overflow-y: overlay;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-left: 16px;
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.form-out-box {
  height: 400px;
  margin-bottom: 20px;
  overflow-y: overlay;
}

.yes-green-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #ecf5ff;
}

.no-red-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  background: #f4f4f5;
  border: 1px solid #f4f4f5;
}

.redItem {
  color: #f0142f;
}

.showErrList {
  height: 200px;
  overflow: scroll;
  width: 250px;
}

::v-deep(.checkbox .el-checkbox) {
  display: block;
}

.noticetext span {
  color: #f0142f;
}
.cityBox {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}
</style>
